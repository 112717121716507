import React from 'react'
import PropTypes from 'prop-types'
import ComingSoon from '../components/comingSoon';
import TitleMain from '../components/titleMain';
import CompetitionCard from '../components/competitionCard';
//css
import '../assets/css/competition.css';

//image
import skh from '../assets/img/competition_skh.png';
import ccc from '../assets/img/competition_ccc.png';

import there from '../assets/img/比赛3.jpg';
import four from '../assets/img/比赛4.jpg';
import five from '../assets/img/图片5.png';
import six from '../assets/img/比赛6.png';

class Competition extends React.Component {
  render() {
    return (
      <div>
        <TitleMain name='賽事' />
        <div className='Competition'>
          <div className='Competition__competitionCard'>
            <CompetitionCard title='「森林小英雄」' subtitle={'AI 機械人救援大挑戰'} img={six} link='https://airrc.plkflpy.edu.hk/' date={'  Apr. 5, 2025 香港城市大學'} />
          </div>
          <div className='Competition__competitionCard'>
            <CompetitionCard title='「城市救援」--新加坡站' subtitle={'亞太區STEM/AI科技創新挑戰賽 InnoBot'} img={four} link='https://gba.cocorobo.hk/' date={' May 10, 2025  新加坡科技設計大學 '} />
          </div>
          <div className='Competition__competitionCard'>
            <CompetitionCard title='「城市自然灾害防护及救援」' subtitle={'粵港澳大灣區科技創新賽 InnoBot'} img={there} link='https://gba.cocorobo.hk/' date={'Jul. 4, 2025'} />
          </div>
          <div className='Competition__competitionCard'>
            <CompetitionCard title='「城市救援」--香港站' subtitle={'亞太區STEM/AI科技創新挑戰賽 InnoBot'} img={five} link='https://gba.cocorobo.hk/' date={'Jul. 12, 2025 香港科學園 '} />
          </div>
          <div className='Competition__competitionCard'>
            <CompetitionCard title='「奪寶奇兵」' subtitle={'聖公會中學3D打印機械人對抗賽'} img={skh} link='https://skh.cocorobo.hk' date={'Jul. 23, 2018'} />
          </div>
          <div className='Competition__competitionCard'>
            <CompetitionCard title='「第一屆機械人對抗賽」' subtitle={'中華基督教會協和小學 (長沙灣)'} img={ccc} link='http://ccc.cocorobo.hk' date={'Feb. 1, 2019'} />
          </div>
        </div>
      </div>
    )
  }
}

export default Competition;
